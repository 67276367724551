<template>
  <div class="small-echarts-content flex-column">
    <span class="font-size-14 small-title-text">{{title}}</span>
    <span class="font-size-30 small-content-text">{{getContentStr()}}<span class="font-size-14">{{getUnitStr()}}</span></span>
    <div
      class="small-charts"
      ref="storeEcharts"
    ></div>
    <div class="row-line-wrapper"></div>
    <div class="flex-row-space-between small-down-wrapper">
      <template v-if="type === 'space'">
        <div class="flex-row">
          <span
            class="font-size-14"
            style="color:rgba(0, 0, 0, 0.65)"
          >月同比</span>
          <span
            class="font-size-14"
            style="color:rgba(0, 0, 0, 0.85);margin-left:0.5rem"
          >{{Math.abs(otherData.value1) + '%'}}</span>
          <div
            v-if="otherData.value1 == 0"
            class="small-line-arrow"
          ></div>
          <span
            v-else
            :class="['iconfont', 'iconxiala', 'font-size-14', otherData.value1 > 0 ? 'small-red-arrow' : 'small-green-arrow']"
          ></span>
        </div>
        <div class="flex-row">
          <span
            class="font-size-14"
            style="color:rgba(0, 0, 0, 0.65)"
          >日环比</span>
          <span
            class="font-size-14"
            style="color:rgba(0, 0, 0, 0.85);margin-left:0.5rem"
          >{{Math.abs(otherData.value2) + '%'}}</span>
          <div
            v-if="otherData.value1 == 0"
            class="small-line-arrow"
          ></div>
          <span
            v-else
            :class="['iconfont', 'iconxiala', 'font-size-14',  otherData.value2 > 0 ? 'small-red-arrow' : 'small-green-arrow']"
          ></span>
        </div>
      </template>
      <template v-else-if="type === 'flow'">
        <div
          class="font-size-14"
          style="color:rgba(0, 0, 0, 0.65)"
        >{{getLastMonthFlow(otherData.value1)}}</div>
      </template>
      <template v-else-if="type === 'items'">
        <div
          class="font-size-14"
          style="color:rgba(0, 0, 0, 0.65)"
        >{{'拍摄数 '+otherData.value1}}</div>
      </template>
      <template v-else>
        <div
          class="font-size-14"
          style="color:rgba(0, 0, 0, 0.65)"
        >{{'项目文件 '+usedResource}}</div>
        <div
          class="font-size-14"
          style="color:rgba(0, 0, 0, 0.65)"
        >{{'拍摄文件 '+otherData.value1}}</div>
      </template>
    </div>
  </div>
</template>

<script>
import { getFileSizeByNumber, getFileSizeByUnit, getFileSize } from '@utils/getFileSize';
let echarts = require("echarts/lib/echarts");
export default {
  name: "SmallEcharts",
  props: {
    seriesData: Array,
    title: String,
    usedResource: Number,
    type: String,
    otherData: Object
  },
  data () {
    return {

    };
  },
  mounted () {
    this.$nextTick(() => {
      if (this.type === 'space' || this.type === 'flow') {
        this.drawEchartsLine();
      } else {
        this.drawEchartsBar();
      }
    });
  },
  methods: {
    drawEchartsLine () {
      let storeEcharts = echarts.init(this.$refs.storeEcharts);
      storeEcharts.setOption({
        grid: {
          left: '0',
          right: '0',
          top: '0',
          containLabel: false,
          width: '100%',
          height: '100%',
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          show: false
        },
        yAxis: {
          type: "value",
          show: false, //是否显示y坐标轴相关内容
        },
        series: [
          {
            data: this.seriesData,
            type: "line",
            smooth: true,//折线光滑
            areaStyle: {
              normal: {
                color: this.type === "space" ? "#D6E1FF" : "#e0f5f9",
              },
            },
            lineStyle: {
              normal: {
                width: 1.5,
                color: this.type === "space" ? "#3468FE" : "#62CFE3",
              },
            },
            symbolSize: 0,//拐点大小
          },
        ],
      });
    },
    drawEchartsBar () {
      let storeEcharts = echarts.init(this.$refs.storeEcharts);
      storeEcharts.setOption({
        grid: {
          left: '0',
          right: '0',
          top: '0',
          containLabel: false,
          width: '100%',
          height: '100%',
        },
        xAxis: {
          type: "category",
          show: false
        },
        yAxis: {
          type: "value",
          show: false, //是否显示y坐标轴相关内容
        },
        series: [
          {
            data: this.seriesData,
            type: "bar",
            itemStyle: {
              color: this.type === "items" ? "#3468FE" : "#FECA7C",
            }
          },
        ],
      });
    },
    getContentStr () {
      if (this.type === 'space' || this.type === 'flow') {
        return this.getSpace(this.usedResource);
      } else if(this.type==='files'){
        return this.usedResource+this.otherData.value1;
      }else{
        return this.usedResource
      }
    },
    getUnitStr () {
      if (this.type === 'space' || this.type === 'flow') {
        return this.getUnit(this.usedResource);
      } else {
        return "";
      }
    },
    getLastMonthFlow (size) {
      let fileSize = size > 0 ? getFileSize(size) : '0B';
      return '上月总流量 ' + fileSize;
    },
    getUnit (size) {
      return size > 0 ? getFileSizeByUnit(size) : 'B';
    },
    getSpace (size) {
      return size > 0 ? getFileSizeByNumber(size) : '0';
    }
  },
};
</script>

<style lang="scss" scoped>
.small-echarts-content {
  width: 100%;
  height: 100%;
  .small-title-text {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.45);
  }
  .small-content-text {
    color: black;
    margin-top: 10px;
    margin-bottom: 14px;
  }
  .small-charts {
    width: 100%;
    height: 46px;
  }
  .row-line-wrapper {
    width: 100%;
    height: 1px;
    background: #e8e8e8;
    margin-top: 4px;
  }
  .small-down-wrapper {
    flex: 1;
  }
  .small-line-arrow {
    width: 8px;
    height: 2px;
    margin-left: 6px;
    background: rgba(0, 0, 0, 0.45);
  }
  .small-green-arrow {
    color: green;
  }
  .small-red-arrow {
    color: red;
    transform: rotate(180deg);
  }
}
</style>