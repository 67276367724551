<template>
  <div class="resource-content flex-column">
      <el-breadcrumb class="breadcrumb-title" separator="/">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>资源概览</el-breadcrumb-item>
        </el-breadcrumb>
    <div class="flex-row-space-between">
      <div class="small-content flexcolumn-row-center">
        <SmallEcharts class="small-wrapper" 
          type="space"
          title="存储用量" 
          :seriesData="[10, 22, 33, 10, 23, 29, 13, 12]"
          :usedResource='usedResource'
          :otherData="usedResourceOtherData"/>
      </div>
      <div class="small-content flexcolumn-row-center">
        <SmallEcharts class="small-wrapper" 
          type="flow"
          title="本月总流量" 
          :seriesData="[500, 300, 111, 200, 50]"
          :usedResource='usedFlow'
          :otherData="usedFlowOtherData"/>
      </div>
      <div class="small-content flexcolumn-row-center">
        <SmallEcharts class="small-wrapper" 
          type="items"
          title="项目数" 
          :seriesData="[10,20,14,12,5,8,33,5,8,22,13,18,2,0,7,5,14,18]"
          :usedResource="projectNum"
          :otherData="projectNumOtherData"/>
      </div>
      <div class="small-content small-content-last flexcolumn-row-center">
        <SmallEcharts class="small-wrapper" 
          type="files"
          title="文件数" 
          :seriesData="[10,20,14,12,5,8,33,5,8,22,13,18,2,0,7,5,14,18]"
          :usedResource="filesNum"
          :otherData="filesNumOtherData"/>
      </div>
    </div>
    <div class="flex-row-space-between">
      <div class="big-content big-content-first flexcolumn-row-center">
        <BigEcharts class="big-wrapper" 
          type="space"
          title="存储用量趋势" 
          :seriesData="storageSeriesData"
          :xAxisData="storagexAxisData"
          @changeCheckDays="requestStatisticsStorage"/>
      </div>
      <div class="big-content flexcolumn-row-center">
        <BigEcharts class="big-wrapper" 
          type="flow"
          title="流量趋势" 
          :seriesData="rateSeriesData"
          :xAxisData="ratexAxisData"
          @changeCheckDays="requestStatisticsRate"/>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@request/api/index";
import SmallEcharts from "./components/SmallEcharts.vue"
import BigEcharts from "./components/BigEcharts.vue"
export default {
  name: "Resource",
  data() {
    return {
      usedResource:0,//存储用量
      usedFlow:0,//本月总流量
      projectNum:0,//项目数
      filesNum:0,//文件数
      usedResourceOtherData:{'value1':0,'value2':0},//存储用量图表附加数据
      usedFlowOtherData:{'value1':0,'value2':0},//本月总流量图表附加数据
      projectNumOtherData:{'value1':0,'value2':0},//项目数图表附加数据
      filesNumOtherData:{'value1':0,'value2':0},//文件数图表附加数据
      storageSeriesData:[0,0,0],//存储用量趋势y轴值
      storagexAxisData:[],//存储用量趋势x轴值
      rateSeriesData:[],//流量趋势y轴值
      ratexAxisData:[],//流量趋势x轴值
    };
  },
  created(){
    this.requestStatisticsResource();
  },
  methods: {
    /**
     * 获取资源概览-统计数据
     */
    async requestStatisticsResource(){
      try{
        const res = await request.resource.requestStatisticsResource();
        this.usedResource = res.data.data.storage*1;
        this.usedFlow = res.data.data.rate*1;
        this.projectNum = res.data.data.project_count*1;
        this.filesNum = res.data.data.project_file_count*1;
        this.usedResourceOtherData.value1 = res.data.data.storage_month;
        this.usedResourceOtherData.value2 = res.data.data.storage_day;
        this.usedFlowOtherData.value1 = res.data.data.up_rate;
        this.projectNumOtherData.value1 = res.data.data.shoot_count;
        this.filesNumOtherData.value1 = res.data.data.shoot_file_count;
      }catch(err){
        console.log(err);
      }
    },
    /**
     * 获取资源概览-存储用量趋势
     */
    async requestStatisticsStorage(params){
      try{
        const res = await request.resource.requestStatisticsStorage(params);
        this.storageSeriesData = res.data.data.data;
        this.storagexAxisData = res.data.data.list;
      }catch(err){
        console.log(err);
      }
    },
    /**
     * 获取资源概览-流量趋势
     */
    async requestStatisticsRate(params){
      try{
        const res = await request.resource.requestStatisticsRate(params);
        this.rateSeriesData = res.data.data.data;
        this.ratexAxisData = res.data.data.list;
      }catch(err){
        console.log(err);
      }
    },
  },
  components:{
    SmallEcharts, BigEcharts
  }
};
</script>

<style lang="scss" scoped>
.resource-content {
  width: 100%;
  min-width: 1184px;
  height: 100%;
  .small-content {
    width: 25%;
    height: 188px;
    margin-right:24px;
    background: white;
    border-radius: 8px;
    .small-wrapper{
      width:86%;
      height:100%;
    }
  }
  .small-content-last{
    margin-right:8px;
  }
  .big-content{
    margin-top: 2%;
    width: 50%;
    height: 472px;
    background: white;
    border-radius: 8px;
    .big-wrapper{
      width:91.7%;
      height:100%;
    }
  }
  .big-content-first{
    margin-right:24px;
  }
}
</style>