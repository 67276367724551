/**
 * 获取年-月-日时间格式
 */
export function getFormatDate(time){
  let day = new Date(time);
  return day.getFullYear() + "-" + (day.getMonth()> 9 ? (day.getMonth() + 1) : "0" + (day.getMonth() + 1)) + "-" +(day.getDate()> 9 ? (day.getDate()) : "0" + (day.getDate()));
}
/**
 * 获取月-日时间格式
 */
 export function getFormatDateMonth(time){
  let day = new Date(time);
  return (day.getMonth()> 9 ? (day.getMonth() + 1) : "0" + (day.getMonth() + 1)) + "-" +(day.getDate()> 9 ? (day.getDate()) : "0" + (day.getDate()));
}
/**
 * 获取年-月时间格式
 */
export function getFormatDateByYM(time){
  let day = new Date(time);
  return day.getFullYear() + "-" + (day.getMonth()> 9 ? (day.getMonth() + 1) : "0" + (day.getMonth() + 1));
}