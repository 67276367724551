<template>
  <div class="big-echarts-content flex-column">
    <div class="title-wrapper flex-row">
      <span class="font-size-16 big-title-text">{{title}}</span>
    </div>
    <div class="tabbar-wrapper flex-row">
      <div class="tabbar-btn font-size-14 flex-all-center pointer"
        :class="{ active: tabbarIndex === 0 }"
        @click="onClickTabber(0)">近7天</div>
      <div class="tabbar-btn font-size-14 flex-all-center pointer"
        :class="{ active: tabbarIndex === 1 }"
        @click="onClickTabber(1)">近30天</div>
      <el-date-picker v-model="dateValue"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        style="width: 16.75rem">
      </el-date-picker>
    </div>
    <div class="big-charts"
      ref="storeEcharts"></div>
  </div>
</template>

<script>
import {
  getFileSize,
  getEchartScaleUnitBySize,
  getFileSizeByUnit,
} from "@utils/getFileSize.js";
import { getFormatDate } from "@utils/dateUtil.js";
export default {
  name: "bigEcharts",
  props: {
    seriesData: Array,
    xAxisData: Array,
    title: String,
    type: String,
  },
  data() {
    return {
      storeEcharts: {},
      tabbarIndex: -1,
      dateValue: "",
      pickerOptions: {
        disabledDate(time) {
          let curDate = new Date().getTime();
          let oneDay = 8.64e7;
          let sixMonth = 60 * oneDay;
          return (
            time.getTime() > curDate - oneDay ||
            time.getTime() < curDate - sixMonth
          );
        },
      },
    };
  },
  watch: {
    dateValue() {
      if (this.dateValue) {
        this.tabbarIndex = -1;
        let startDate = getFormatDate(new Date(this.dateValue[0]).getTime());
        let endDate = getFormatDate(new Date(this.dateValue[1]).getTime());
        const params = { startDate, endDate };
        this.$emit("changeCheckDays", params);
      }
    },
    seriesData(data) {
      this.drawEchartsLine();
    },
    xAxisData() {
      this.drawEchartsLine();
    },
  },
  mounted() {
    this.echartInit();
    this.onClickTabber(0);
  },
  methods: {
    echartInit() {
      this.storeEcharts = this.$echarts.init(this.$refs.storeEcharts);
    },
    drawEchartsLine() {
      const maxYNumber = Math.max.apply(null, this.seriesData);
      const yDivisor = getEchartScaleUnitBySize(maxYNumber);
      const yUnit = getFileSizeByUnit(maxYNumber);
      this.storeEcharts.setOption({
        tooltip: {
          trigger: "axis",
          formatter: (value) => {
            let str = value[0].axisValue + "<br />";
            str += value[0].marker;
            str +=
              value[0].data > 0 ? getFileSize(value[0].data) : "0B" + "<br />";
            return str;
          },
        },
        grid: {
          top: 30,
          width: "90%",
          height: "80%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xAxisData.map((item) => item.substring(5)),
          axisTick: {
            lineStyle: {
              color: "#e9e9e9",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#e9e9e9",
            },
          },
          axisLabel: {
            color: "#6f6f6f",
          },
        },
        yAxis: {
          type: "value",
          name: yUnit,
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            color: "#6f6f6f",
            formatter: (value) => {
              if(yUnit){
                const result = value/ yDivisor; // * yDivisor;
                return result;
              }else{
                return value;
              }
            },
          },
        },
        series: [
          {
            data: this.seriesData,
            type: "line",
            smooth: true, //折线光滑
            areaStyle: {
              normal: {
                color: this.type === "space" ? "#D6E1FF" : "#e0f5f9",
              },
            },
            lineStyle: {
              normal: {
                width: 1.5,
                color: this.type === "space" ? "#3468FE" : "#62CFE3",
              },
            },
            showSymbol: false, //拐点大小
            symbolSize: 1, //拐点大小
            symbol: "circle",
            itemStyle: {
              normal: {
                color: this.type === "space" ? "#3468FE" : "#62CFE3",
              },
            },
          },
        ],
      });
    },
    onClickTabber(index) {
      if (this.tabbarIndex !== index) {
        this.dateValue = "";
        this.tabbarIndex = index;
        let startDate = this.getLastTime(index == 0 ? 7 : 30);
        let endDate = this.getLastTime(1);
        const params = { startDate, endDate };
        this.$emit("changeCheckDays", params);
      }
    },
    /**
     * 获取前day天的年-月-日
     */
    getLastTime(day) {
      let time = new Date().getTime() - 24 * 60 * 60 * 1000 * day;
      return getFormatDate(time);
    },
  },
};
</script>

<style lang="scss" scoped>
.big-echarts-content {
  width: 100%;
  height: 100%;
  .title-wrapper {
    width: 100%;
    height: 62px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .big-title-text {
      color: rgba(0, 0, 0, 1);
    }
  }
  .tabbar-wrapper {
    width: 100%;
    height: 69px;
    .tabbar-btn {
      width: 60px;
      height: 23px;
      border-radius: 6px;
      background: #eff2fb;
      color: rgba(0, 0, 0, 0.45);
      margin-right: 12px;
      &.active {
        background: #3468fe;
        color: white;
      }
    }
  }
  .big-charts {
    width: 100%;
    height: 300px;
    margin-bottom: 43px;
  }
}
</style>